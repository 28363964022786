
import { Component, Vue } from 'vue-property-decorator';
import { mdiEye, mdiEyeOff } from '@mdi/js';

import { UserModule } from '@/store/modules';
import { ApiService, UserService } from '@/services';
import { IAuthUser } from '@/types/user.types';

@Component
export default class ChangePassword extends Vue {
  visibleOLD = false;
  visibleNEW = false;
  oldPassword = null;
  newPassword = null;

  get user(): IAuthUser | null {
    return UserModule.user;
  }
  icons = {
    mdiEye: mdiEye,
    mdiEyeOff: mdiEyeOff
  };

  checkPass(oldPassword: string, newPassword: string): boolean {
    return oldPassword != null && newPassword != null;
  }

  get appendIconOLD(): string | null {
    return this.visibleOLD ? mdiEye : mdiEyeOff;
  }

  get appendIconNEW(): string | null {
    return this.visibleNEW ? mdiEye : mdiEyeOff;
  }

  get typeOLD(): string {
    return this.visibleOLD ? 'text' : 'password';
  }

  get typeNEW(): string {
    return this.visibleNEW ? 'text' : 'password';
  }

  onToggleVisibilityOLD(): void {
    this.visibleOLD = !this.visibleOLD;
  }

  onToggleVisibilityNEW(): void {
    this.visibleNEW = !this.visibleNEW;
  }

  async updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    try {
      // we should do this in auth.service.ts
      const response = await ApiService.api.post('/auth/change-password', {
        oldPassword,
        newPassword
      });
      if (response.status == 201 || response.status == 200) {
        this.$notifySuccess('Your password has been changed successfully');
      }
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (err: any) {
      const errorMsg = this.$getError(err);
      this.$notifyError(errorMsg);
    }
  }
}
